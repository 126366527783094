* {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', serif;  
  font-weight: 300;
}
input, select         {
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:100%;
  border:none;
  border-bottom:1px solid #757575;
  margin-left:auto; 
  margin-right:auto;
}
input:focus {
outline: none;
}
button {
  padding: 6px 4px; 
  outline: none; 
  background: rgb(255, 255, 255); 
  border: 2px solid rgb(241, 241, 241); 
  border-radius: 4px;
}
h1 {
  height: 100%;
  margin: 0;
  text-align: center; 
  font-size: 4.5em;
  font-weight: 100;
}
b {
  font-weight: 400;
}
.translation {
  direction: rtl;
  height: 100%;
  margin-bottom: 50em;
  font-size: 2em;
  font-weight: 50;
}
.subtitle {
  direction: ltr;
  height: 50% !important;
  margin-bottom: 0.5em;
  font-size: 0.5em !important;
  font-weight: 50;
  text-align: center; 
}
.arabicWord {
  display:inline-block;
  text-align: center;
  margin-left: 2%;
}
.arabicWord[data-value]:after {
  content: attr(data-value);
  text-align: center;
  display: block; 
  top: 1em;
  z-index: 20;
  font-size: 0.5em;
  font-weight: 400;
  border-top: 0.5px solid #9E9E9E;
  margin-top: 5%;
}
.arabicWord[data-parent]:before {
  content: attr(data-parent);
  text-align: center;
  display: block; 
  top: 1em;
  z-index: 20;
  font-size: 0.5em;
  font-weight: 400;
  margin-top: 20%;
  margin-bottom: 5%;
  white-space: pre-line;
  direction: ltr;
  border-bottom: 0.5px solid #9E9E9E;
}
h2 {
  height: 100%;
  margin-bottom: 0.5em;
  font-size: 2em;
  font-weight: 300;
}
h3 {
  height: 100%;
  margin-bottom: 0.5em;
  font-size: 1.5em;
  font-weight: 300;
}
h4 {
  height: 100%;
  margin-bottom: 0.5em;
  font-size: 1.25em;
  font-weight: 300;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
.header {
  text-align: center;
  padding: 0;
}
.header li a, .dropbtn {
  color: #000;
  font-size: 1.5em;
  font-weight: 300;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  outline: none;
}
.header li a:hover, .dropbtn:hover {
  font-style: italic;
}
.header li a.active {
  font-weight: 500;
}
.dropdown {
  display: inline;
  overflow: auto;
}
.dropbtn {
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}
/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: relative;
  background-color: #f9f9f9;
}
.dropdown:hover .dropdown-content {
  display: inline-block;
}
.content {
  display: block;
  max-width: 1000px;
  padding-left: auto;
  padding-right: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  width: 100%;
  flex: 1 0 auto;
}
/* here the rules for small windows */
@media (max-width: 650px) { 
  .react-pdf__Document {
    visibility: hidden;
  } 
}
/* here the rules for small windows */
@media (max-width: 950px) { 
  .baby img {
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .year,.term,.occurrences,.sentiment,.bss,.rsa {
    float: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .occurrences {
      float: none;
      color: #368fe2;
  }
  .bss {
      float:none;
      color: #5ec26a;
  }
  .rsa {
      float: none;
      color: #f7a35c;
  }
}

/* here the rules for windows above 900px */
@media (min-width: 950px) {
  .baby img {
    max-width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .year,.term {
    float: left;
  }
  .react-pdf__Document {
    margin-right: 40px;
  } 
  .occurrences {
    float: left;
    color: #368fe2;
    }
  .bss {
      float: right;
      color: #5ec26a;
  }
  .sentiment {
    float: left;
    }
  .rsa {
      float: right;
      color: #f7a35c;
  }
}
.baby {
  text-align: center;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.social {
  text-align: center;
  margin-bottom: 1em;
}
.social a {
  margin-right: 1em;
}
.social a img {
  width: 25px;
  margin-bottom: 1em;
}
.movies,.shows,.games,.books,.boardgames {
  float: left;
}
.term {
  min-width: 300px;
  max-width: 300px;
}
.title {
  align-content: center;
  text-align: center;
}
.finalYear {
  float: inline-start;
}
canvas {
  padding: 0;
  margin: auto;
  display: block;
}
.reflectionTitle {
  height: 100%;
  margin: 0;
  font-size: 4.5em;
  font-weight: 100;
}
.occurrences, .sentiment, .bss, .rsa {
  min-width: 325px !important;
  max-width: 325px !important;
}
.mui-form {
  text-align: center;
}
a { 
  text-decoration: none; 
}
.ReactTable .rt-tbody{
  overflow: overlay;
}
/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}
.blog {
  width: 70%;
  text-align: center;
  margin:0 auto;
}
.blog > *:not(:last-child) {
  display: block;
  margin-bottom: 40px; 
} 

.blogEntry {
  text-align: left;
}
.ant-form-item-explain {
  display: none;
}

.search {
  float:left;
  padding: 1em;
  width: 100%;
}